.profile__container {
  max-width: 1360px;
  position: relative;
  display: flex;
  margin: 47px auto 80px;
}

@media screen and (max-width: 710px) {
  .profile__container {
    flex-direction: column;
  }
}
