@font-face {
  font-family: "Cabinet Grotesk";
  src: url("../../fonts/CabinetGrotesk-Regular.otf");
  /* Should i put a format name for openType*/
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1360px;
  margin: 0 auto;
}
.header__container {
  display: flex;
  align-items: center;
}
.header__logo {
  max-height: 40px;
  max-width: 89px;
}
.header__info {
  margin: 0 0 0 24px;
  font-family: "Cabinet Grotesk", "Arial", sans-serif;
  line-height: 24px;
  font-size: 20px;
  font-weight: 700;
}
.header__nav {
  display: flex;
  justify-content: flex-end;
}
.navigation__container {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.navigation__button {
  padding: 0;
  border: none;
  background-color: #dcdcdc;
  font-family: "Cabinet Grotesk", "Arial", sans-serif;
  font-family: Cabinet Grotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.navigation__button:hover {
  cursor: pointer;
  opacity: 50%;
}
.navigation__header-user {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
}
.navigation__user-info {
  margin: 0 10px 0;
  font-family: "Cabinet Grotesk", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  /* Long names */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90px;
}
.navigation__user-info:hover {
  cursor: pointer;
  opacity: 60%;
}
.navigation__avatar {
  width: 30%;
  height: 40px;
}
.nav__register-button {
  border: none;
  background-color: #dcdcdc;
  font-family: "Cabinet Grotesk", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
}
.nav__login-button {
  border: none;
  background-color: #dcdcdc;
  font-family: "Cabinet Grotesk", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
}
.nav__register-button:hover,
.nav__login-button:hover {
  opacity: 70%;
  cursor: pointer;
}

@media screen and (max-width: 780px) {
  .header__container {
    flex-direction: column;
    align-items: flex-start;
  }
  .header__info {
    font-size: 13px;
    margin: 0;
  }
  .header__logo {
    max-height: 20px;
    max-width: 50px;
  }
  .navigation__container {
    padding: 0;
  }
  .navigation__button {
    font-size: 13px;
  }
  .navigation__user-info {
    max-width: 70px;
    font-size: 13px;
  }
  .nav__register-button {
    font-size: 13px;
  }
  .nav__login-button {
    font-size: 13px;
  }
  .navigation__user-info {
    max-width: 55px;
    font-size: 13px;
  }
  .navigation__avatar {
    height: 30px;
  }
}

@media screen and (max-width: 500px) {
  .header {
    align-items: baseline;
  }
  .navigation__container {
    padding: 0;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  .nav__register-button {
    padding: 0;
  }
  .nav__login-button {
    padding: 0;
  }
}
