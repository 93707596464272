@font-face {
  font-family: "Cabinet Grotesk";
  src: url("../../fonts/CabinetGrotesk-Regular.otf");
}

.footer {
  max-width: 1360px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.footer__text {
  margin: 0;
  font-family: "Cabinet Grotesk";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

@media screen and (max-width: 500px) {
  .footer__text {
    font-size: 10px;
  }
}
