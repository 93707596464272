/* Removed default styles */
@import url(../src/components/App/App.css);
@import url(../src/components/Header/Header.css);
@import url(../src/components/Main/Main.css);
@import url(../src/components/ItemCard/ItemCard.css);
@import url(../src/components/WeatherCard/WeatherCard.css);
@import url(../src/components/Footer/Footer.css);

@import url(../src/components/ItemModal/ItemModal.css);
@import url(../src/components/ModalWithForm/ModalWithForm.css);

/* Body of HTML */
.body {
  margin: 0;
  background-color: #dcdcdc;
}
