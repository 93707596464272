@font-face {
  font-family: "Cabinet Grotesk";
  src: url("../../fonts/CabinetGrotesk-Regular.otf");
}

.weather-card {
  height: 80px;
  margin: 28px auto;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  /* Left side of forecast image won't be rounded */
}
.weather-card__temp {
  font-family: "Cabinet Grotesk", "Arial", sans-serif;
  color: white;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  z-index: 1;
  align-self: center;
  margin: 0 0 0 16px;
}
.weather-card__wrapper {
  position: relative;
}
.weather-card__image {
  position: absolute;
  right: 0;
}

@media screen and (max-width: 350px) {
  .weather-card__temp {
    font-size: 22px;
  }
}
