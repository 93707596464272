@font-face {
  font-family: "Cabinet Grotesk";
  src: url("../../../fonts/CabinetGrotesk-Regular.otf");
}
.sidebar__container {
  margin-right: 116px;
}
.sidebar__info {
  display: flex;
  align-items: center;
}
.sidebar__avatar {
  width: 56px;
  height: 56px;
  margin: 0 15px 15px 0;
}
.sidebar__name {
  font-family: "Cabinet Grotesk", "Arial", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  /* Custom Name area */
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 205px;
  max-width: 200px;
  position: absolute;
  left: 70px;
}
.sidebar__options {
  font-family: "Cabinet Grotesk", "Arial", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  margin: 0;
}
.sidebar__profile-data {
  margin: 0 0 8px 0;
  width: 175px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}

.sidebar__profile-data:hover {
  opacity: 0.5;
}
.sidebar__logout {
  margin: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}
.sidebar__logout:hover {
  opacity: 0.5;
}
@media screen and (max-width: 500px) {
  .sidebar__container {
    margin-right: 0;
  }
  .sidebar__name {
    font-size: 15px;
  }
  .sidebar__profile-data {
    font-size: 15px;
  }
  .sidebar__logout {
    font-size: 15px;
  }
}
