@font-face {
  font-family: "Cabinet Grotesk";
  src: url("../../fonts/CabinetGrotesk-Regular.otf");
}

.item-modal {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.item-modal__container {
  background-color: white;
  position: relative;
  border-radius: 16px;
  cursor: default;
}
.item-modal__close-button {
  background: transparent url(../../images/close-button.svg) center no-repeat;
  border: none;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 27px;
  top: 27px;
}
.item-modal__close-button:hover {
  opacity: 50%;
  cursor: pointer;
}
.item-modal__image {
  max-width: 85vw; /* Adjusts image to screen and leaves space */
  max-height: 75vh;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin-bottom: 20px;
}

.item-modal__info {
  height: 100px;
  font-family: "Cabinet Grotesk", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 32px 0 32px;
}

.item-modal__title {
  margin: 0 0 12px 0;
}
.item-modal__weather {
  margin: 0;
}
.item-modal__delete-button {
  border: none;
  background-color: white;
  color: red;
  font-family: "Cabinet Grotesk", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.item-modal_delete-button_hidden {
  display: none;
}
.item-modal__delete-button:hover {
  opacity: 50%;
  cursor: pointer;
}
@media screen and (max-width: 575px) {
  .item-modal__title {
    font-size: 12px;
  }
  .item-modal__weather {
    font-size: 12px;
  }
  .item-modal__delete-button {
    font-size: 12px;
  }
}
@media screen and (max-width: 350px) {
  .item-modal__info {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .item-modal__delete-button {
    padding: 0;
  }
}
