/* Removed default styles */
.page {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
}
.page__container {
  padding: 32px 40px 20px;
}
