@font-face {
  font-family: "Cabinet Grotesk";
  src: url("../../fonts/CabinetGrotesk-Regular.otf");
}

.main {
  margin: 0 auto;
  max-width: 1360px;
}

.main__header {
  font-family: "Cabinet Grotesk", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin: 40px 0 0 0;
}

.main__items {
  flex-wrap: wrap;
  display: flex;
  grid-gap: 20px 17px;
  margin: 34px 0 170px 0;
  padding: 0;
}
@media screen and (max-width: 780px) {
  .main__header {
    font-size: 15px;
  }
}
@media screen and (max-width: 500px) {
  .main__items {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, 140px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
