@font-face {
  font-family: "Cabinet Grotesk";
  src: url("../../fonts/CabinetGrotesk-Regular.otf");
}

.card {
  list-style: none;
  max-width: 320px;
  max-height: 320px;
  position: relative;
  overflow: hidden;
}
.card__wrapper {
  display: flex;
  justify-content: center;
}
.card__title {
  font-family: "Cabinet Grotesk", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  /* Position */
  position: absolute;
  top: 2%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Border of Title */
  padding: 2px 12px 2px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.6);
  background-color: #f2f2f2;
  /* Long card name*/
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.card__like-button {
  background: transparent url("../../images/empty_like-button.svg") center
    no-repeat;
  width: 21px;
  height: 19px;
  border: none;
  opacity: 100%;
  cursor: pointer;
  position: absolute;
  top: 14%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card__like-button:hover {
  cursor: pointer;
  opacity: 0.7;
}
.card__like-button_liked {
  background: transparent url("../../images/liked_like-button.svg") center
    no-repeat;
  width: 21px;
  height: 19px;
  border: none;
  opacity: 100%;
  cursor: pointer;
  position: absolute;
  right: 205px;
}

.card__image {
  border-radius: 20px;
  width: 320px;
  height: 320px;
  cursor: pointer;
}
.card__like-button_hidden {
  display: none;
}

@media screen and (max-width: 500px) {
  .card__title {
    max-width: 115px;
    transform: none;
    left: 10px;
    top: 91px;
    padding: 2px;
  }
  .card__like-button {
    transform: none;
    left: 10px;
    top: 10px;
  }
  .card__like-button_liked {
    transform: none;
    left: 10px;
    top: 10px;
  }
  .card__image {
    width: 140px;
    height: 140px;
  }
}
